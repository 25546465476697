.title {
    display: flex;
    align-items: center;
    height: 100%;
}
.title h2 {
    font-size: 48px;
    line-height: 72px;
    margin-top: 60px;
    letter-spacing: -0.5px;
    margin-bottom: 50px;
}
.title p {
    font-size: 18px;
    line-height: 35px;
    opacity: 0.5;
    letter-spacing: -0.5px;
    max-width: 480px;
}
.evidence {
    position: relative;
}
.evidence:before {
    content: "";
    display: block;
    background-color: var(--main-color);
    position: absolute;
    width: 120px;
    height: 26px;
    z-index: -1;
    left: 0px;
    bottom: 16px;
}
