.heroHome {
    background: linear-gradient(#000 99%, #fff 1%);
    padding: 100px 0 0px;
    position: relative;
    z-index: 2;
    overflow: hidden;
}
.tokenomicsBg {
    padding: 70px 0 10px;
    position: relative;
    z-index: 2;
    overflow: hidden;
}
.generalSlider {
    margin: 100px 0;
}
.partnerRow {
    background-color: var(--card-color);
    padding: 65px 0 80px;
    position: relative;
    z-index: 2;
}
.form {
    margin-block: 100px;
}

.rightShere {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(calc(-50% + 690px));
    z-index: 8;
    width: 900px;
}
.leftShere {
    position: absolute;
    top: 350px;
    right: 50%;
    transform: translateX(calc(-50% - 100px));
    z-index: 8;
    width: 500px;
}
.dubai {
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translateX(calc(-50%));
    z-index: 8;
    width: 900px;
}
.coin3 {
    position: absolute;
    top: 90px;
    right: 50%;
    transform: translateX(calc(-50% - 300px));
    z-index: 8;
    /* width: 1000px; */
}
.coin2 {
    position: absolute;
    bottom: -10px;
    right: 50%;
    transform: translateX(calc(-50% - 70px));
    z-index: 8;
    /* width: 1000px; */
}
.coin1 {
    position: absolute;
    bottom: 190px;
    left: 50%;
    transform: translateX(calc(-50% + 330px));
    z-index: 8;
    /* width: 1000px; */
}
.logo {
    max-height: 70px;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
}
