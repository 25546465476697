.header {
    text-align: center;
}
.header h2 {
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    letter-spacing: -0.5px;
}
.header p {
    font-size: 18px;
    line-height: 35px;
    letter-spacing: -0.5px;
    opacity: 0.5;
    margin: 0 auto;
    max-width: 550px;
}
.evidence {
    position: relative;
}
.evidence:before {
    content: "";
    display: block;
    background-color: var(--main-color);
    position: absolute;
    width: 100%;
    height: 26px;
    z-index: -1;
    left: -12px;
    bottom: 16px;
}
.partnerBox {
    text-align: center;
}
.partnerBox .imgBoxOne img,
.partnerBox .imgBoxTwo img {
    max-height: 50px;
    margin: 25px 20px 0;
}
.partnerBox .imgBoxOne img:nth-child(2) {
    margin-top: 37px;
}

@media screen and (max-width: 550px) {
    .header p {
        max-width: 100%;
    }
}
