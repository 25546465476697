.header {
    margin-bottom: 25px;
}

.header h2 {
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 48px;
    line-height: 58px;
    font-weight: 700;
}

.supHeader h5 {
    text-align: center;
    font-weight: 700;
    font-size: 12.6059px;
    line-height: 17px;
    letter-spacing: 0.969682px;
    text-transform: uppercase;
    color: var(--small-text-blue);
    margin-bottom: 20px;
}
