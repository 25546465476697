.buttonClass {
    width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    border-radius: 12px;
    background-color: var(--main-color);
    color: var(--dark-text);
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.2px;
    cursor: pointer;
}

.buttonDisabled {
    opacity: 0.6;
    cursor: not-allowed;
}
