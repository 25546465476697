.box {
    background: #262626;
    border: 1px solid #868686;
    box-shadow: 0px 22px 65px rgba(0, 0, 0, 0.25);
    border-radius: 39px;
    color: white;
    padding-top: 80px;
    padding-bottom: 62px;
    background-image: url("../../Assets/img/bgForm.png");
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: bottom right;
}
.box h2 {
    margin-bottom: 31px;
    text-align: center;
    font-size: 38px;
    line-height: 57px;
    letter-spacing: -0.5px;
    z-index: 2;
    position: relative;
}
.box p {
    font-weight: 500;
    font-size: 21px;
    line-height: 35px;
    opacity: 0.5;
    text-align: center;
    letter-spacing: -0.5px;
    margin-bottom: 53px;
    max-width: 990px;
    margin-inline: auto;
    padding-inline: 5px;
}
.form {
    background: rgba(26, 26, 26, 0.342);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.17);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 20px;
    padding: 40px 40px 50px;
    max-width: 700px;
    margin-inline: 62px 0;
    position: relative;
}
.form h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.5px;
    margin-bottom: 28px;
}

.success {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #363636;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    flex-direction: column;
    z-index: 999;
}
.success p {
    max-width: 500px;
    color: var(--main-color);
    opacity: 1;
    margin-bottom: 0;
    font-size: 30px;
}

.countdown {
    color: white !important;
    font-size: 16px !important;
    margin-top: 8px;
}

.evidence {
    position: relative;
}
.evidence:before {
    content: "";
    display: block;
    background-color: var(--main-color);
    position: absolute;
    width: 93%;
    height: 26px;
    z-index: -1;
    left: 0px;
    bottom: -1px;
}
.error {
    margin-top: 20px;
    color: red;
    padding-left: 4px;
}
.label {
    color: var(--secondary-text-color);
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
    padding-left: 3px;
    margin-bottom: 9px;
}

@media screen and (max-width: 991px) {
    .box {
        background-size: 100%;
        background-position: bottom right;
    }
    .form {
        margin-inline: 0;
        padding: 40px 10px 50px;
    }
}
