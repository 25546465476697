.componentContainer {
    margin-bottom: 10px;
}

.label {
    color: var(--secondary-text-color);
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
    padding-left: 3px;
    margin-bottom: 9px;
}

.inputFieldContainerEmpty {
    background: #484848;
    border-radius: 18px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;
}

.inputFieldContainer {
    background: #484848;
    border-radius: 18px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;
}

.inputFieldContainerError {
    background: #484848;
    border-radius: 18px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;
}

.inputFieldContainerError svg {
    stroke: var(--alert-color);
}

.inputClass {
    width: 100%;
}

.input {
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
    color: var(--light-text);
    caret-color: var(--secondary-color);
    padding: 0 1px;
    width: 100%;
}

.componentContainer span {
    color: var(--input-border-color);
    display: flex;
    align-items: center;
}

.componentContainer svg {
    font-size: 20px;
}

.alertIcon {
    color: var(--alert-color);
}

.successIcon {
    color: var(--main-color);
}

.deleteIcon {
    color: var(--light-text);
    cursor: pointer;
}

.errorMessage {
    max-width: 500px;
    min-height: 20px;
    padding: 0 15px;
    margin-top: 5px;
    font-size: 13px;
    color: var(--alert-color);
    text-align: center;
}
