.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* min-height: 736px; */
    padding-top: 70px;
    padding-bottom: 80px;
}
.header {
    color: var(--white-color);
    text-align: center;
}
.header h1 {
    font-weight: 700;
    font-size: 42px;
    line-height: 63px;
    letter-spacing: -0.5px;
    margin-bottom: 30px;
}
.header p {
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: -0.5px;
    opacity: 0.5;
    max-width: 850px;
    margin-inline: auto;
}
.buttons {
    display: flex;
    margin: 60px 0 0;
    justify-content: center;
    position: relative;
}
.buttons .line {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(calc(-50% + 30px));
    z-index: -1;
}
.buttons button:first-child {
    display: flex;
}
.buttons img {
    max-height: 30px;
}
.evidence {
    position: relative;
}
.evidence:before {
    content: "";
    display: block;
    background-color: var(--main-color);
    position: absolute;
    width: 100%;
    height: 26px;
    z-index: -1;
    left: 0px;
    top: 28px;
    bottom: 16px;
}

.project {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.30603px;
    color: var(--white-color);
    padding-bottom: 100px;
}
.project img.logo {
    max-height: 250px;
}
.curve img {
    width: 100%;
}

@media screen and (max-width: 1399px) {
    .project {
        margin-top: 40px;
    }
}

@media screen and (max-width: 991px) {
    .header {
        text-align: center;
    }
    .video video {
        max-height: 330px;
    }
    .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .statsValue {
        justify-content: center;
        align-items: center;
    }
    .project {
        margin-top: 0px;
    }
}

@media screen and (max-width: 460px) {
    .buttons {
        flex-direction: column;
    }
    .buttons button:first-child {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .statsValue {
        flex-direction: column;
    }
    .statsValue .values:first-child {
        margin-bottom: 20px;
    }
    .statsValue .separator {
        display: none;
    }
}
@media screen and (max-width: 390px) {
    .project img {
        margin-left: 0;
        max-height: 30px;
    }
    .logo {
        width: 250px;
    }
}
