.coins {
    text-align: right;
    position: relative;
}
.coins img {
    max-height: 500px;
}
.write {
    position: absolute;
    top: 250px;
}
.shape {
    position: absolute;
    right: -125px;
    top: -100px;
}

@media screen and (max-width: 767px) {
    .write {
        top: 110px;
        left: 5%;
    }
    .coins img {
        max-height: 300px;
    }
    .write img {
        max-height: 200px;
    }
    .shape {
        right: -80px;
        top: -80px;
    }
    .coins {
        text-align: right;
        position: relative;
        transform: translateY(-40px);
    }
}
