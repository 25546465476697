.mainContainer {
    padding: 13px 20px;
    background-color: var(--main-color);
    color: var(--white-color);
    border-radius: 7.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}
