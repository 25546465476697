@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

:root {
    --main-color: #01d085;
    --main-color-opacity: #01d08533;
    --white-color: #ffffff;
    --black-color: #000000;
    --footer-color: #2b2c31;
    --error-color: #f30a34;
    --card-color: #f8f8f8;
    --small-text-blue: #2b59ff;
    --card-dark-color: #282a37;
    --dropdown-dark-color: #12141d;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
}
.noscroll {
    overflow: hidden;
}
#mobileMenuId {
    overflow: auto;
}
#mobileMenuId.active {
    transform: translateX(0);
    transition: all 0.5s ease-in-out;
}

/* Swiper pagination  */
.swiper-pagination-bullet {
    height: 15px !important;
    width: 15px !important;
}
.swiper-pagination-bullet-active {
    background: var(--main-color) !important;
    height: 15px !important;
    width: 15px !important;
}

/* Spacing section ID */
#cardSlider {
    margin: 50px 0 100px;
}
#boxThreeCard {
    margin: 50px 0 100px;
}
#fourCards {
    margin: 100px 0;
}

/* Swiper  */
.sliderGeneral .swiper-button-next,
.sliderGeneral .swiper-button-prev {
    height: 47px;
    width: 47px;
    background: var(--main-color);
    border-radius: 10px;
    color: var(--black-color);
    font-size: 25px;
    border: 3px solid var(--main-color);
}
.sliderGeneral .swiper-button-next {
    margin-left: 10px;
}
.sliderGeneral .swiper-button-next div,
.sliderGeneral .swiper-button-prev div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.sliderGeneral button.swiper-button-next:disabled,
.sliderGeneral button.swiper-button-prev:disabled {
    background-color: transparent;
}
.sliderGeneral .swiper {
    position: unset !important;
}

#launchSlider .swiper-pagination {
    display: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* Query */
@media screen and (min-width: 992px) {
    #mobileMenuId.active {
        width: 0;
        transition: all 0.5s ease-in-out;
    }
}
@media screen and (max-width: 1199px) {
    .useful {
        position: relative;
    }

    h2 {
        font-size: 32px !important;
    }
}

.PhoneInputInput {
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
    color: var(--light-text);
    caret-color: var(--secondary-color);
    background: #484848;
    border-radius: 18px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 457px;
}
